.App {
  text-align: center;
  background-color: black;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} 

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.relative-container {
  position: relative;
}

.App-header {
  height: 50vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Sulphur Point', sans-serif;
  color: #4FAEB4;
  padding: 0%;
  font-size: 120px;
  user-select: none
}

.App-header-legend {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Sulphur Point', sans-serif;
  color: #618082;
  padding: 0%;
  position: absolute;
  font-size: 15px;
  top: 30vh;
  user-select: none
}

.App-header p {
  margin: 0;
}

.App-header-legend p {
  margin-top: 0;
  margin-left: 25%
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive design for different devices */

/* The initial design is always for mobile (portrait)*/

/* For pro max/ultra mobile (portrait)*/
@media all and (min-width: 400px) {
  .App-header{
    font-size: 130px;
  }
  .App-header-legend{
    font-size: 16px;
    top: 30vh
  }
}

/* For all mobile (landscape)*/
@media all and (min-width: 600px) {
  .App-header{
    font-size: 260px;
  }
  .App-header-legend{
    font-size: 25px;
    top: 49vh
  }
}

/* For small tablet (portrait)*/
@media all and (min-width: 600px) and (min-height: 450px) {
  .App-header{
    font-size: 260px;
  }
  .App-header-legend{
    font-size: 25px;
    top: 33vh
  }
}

/* For big (pro) tablet (portrait)*/
@media all and (min-width: 1024px) {
  .App-header{
    font-size: 360px;
  }
  .App-header-legend{
    font-size: 35px;
    top: 35vh
  }
}

/* For small tablet (landscape)*/
@media all and (min-width: 1080px) {
  .App-header{
    font-size: 360px;
  }
  .App-header-legend{
    font-size: 35px;
    top: 41vh
  }
}

/* For laptop with MDPI*/
@media all and (min-width: 1280px) {
  .App-header{
    font-size: 420px;
  }
  .App-header-legend {
    font-size: 42px;
    top: 44vh
  }
}

/* For laptop with touch*/
@media all and (min-width: 1280px) and (min-height: 900px) {
  .App-header{
    font-size: 420px;
  }
  .App-header-legend {
    font-size: 42px;
    top: 42vh
  }
}


/* For laptop with HiDPI*/
@media all and (min-width: 1440px) {
  .App-header{
    font-size: 480px;
  }
  .App-header-legend {
    font-size: 44px;
    top: 46vh
  }
}

/* For 1080p Full HD Monitor*/
@media all and (min-width: 1920px) {
  .App-header{
    font-size: 590px;
  }
  .App-header-legend {
    font-size: 52px;
    top: 46vh
  }
}

/* For 4K Ultra HD Monitor*/
@media all and (min-width: 3840px) {
  .App-header{
    font-size: 1150px;
  }
  .App-header-legend {
    font-size: 90px;
    top: 46vh
  }
}
